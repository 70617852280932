import axios from "axios"
import store from "@/store";
import router from "@/router";
import {googleLogout} from "vue3-google-login";


const instance = axios.create({
  baseURL: 'https://quilt.gg/',
  //baseURL: 'http://localhost:5001/',
  timeout: 5000,
  withCredentials: true,
})


// (example)
// install a request interceptor that adds our authorization token
// to outbound requests
//
//instance.interceptors.request.use(function (config) {
//  return new Promise(async (resolve, reject) => {
//    try {
//      resolve(config)
//    } catch (error) {
//      reject(error)
//    }
//  })
//})


// install a response interceptor that will handle re-authorization flows
//
instance.interceptors.response.use(undefined, async function(error) {
  if (error && error.response) {
    if (error.response.status === 401) {
      await this.logout()
        //googleLogout()
        //window.localStorage.removeItem('user')
        //await instance({method: "GET", url: "/api/auth/logout"})
      //return router.push("/login")
    }
  }
});


export default {
  logout: function() {
    return new Promise(async (resolve, reject) => {
      try {
        googleLogout()
        window.localStorage.removeItem('user')
        await instance({method: "POST", url: "/api/auth/logout"})
        await router.push("/login")
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  login: function(provider, creds) {
    return new Promise(async (resolve, reject) => {
      try {
        await instance({
          method: "POST",
          url: "/api/auth/v1/oauth/" + provider,
          data: creds
        })

        let me_response = await instance({
          method: "GET",
          url: "/api/auth/v1/identity/me",
        })

        window.localStorage.setItem('user', JSON.stringify(me_response.data))

        resolve(me_response.data)

      } catch (error) {
        reject(error)
      }
    })
  },
  checkAuthStatus: function() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await instance({
          method: "GET",
          url: "/api/auth/check",
        })
        // todo: when 400/401, do we make it here?
        resolve(response.data)
      } catch (error) {
        // todo: do something here?
        reject(error)
      }
    })
  },
  getAccessToken: function() {
    return new Promise(async (resolve, reject) => {
      try {
        let token_response = await instance({
          method: "GET",
          url: "/api/auth/v1/oauth/token"
        })

        resolve(token_response.data)

      } catch (error) {
        reject(error)
      }
    })
  },
  getStorageManifest: function(path) {
    return new Promise(async (resolve, reject) => {
      try {
        let storage_response = await instance({
          method: "GET",
          url: "/api/storage/v1/" + path
        })

        let manifest = {"count": 0, "files:": []}

        if (storage_response) {
          manifest = storage_response.data
        }

        resolve(manifest)

      } catch (error) {
        reject(error)
      }
    })
  },
}